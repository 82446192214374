/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:b9185a2e-df97-4159-8057-2805bf04fee1",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_rUH2mlecx",
    "aws_user_pools_web_client_id": "snnc9men9rlugoltqn7g7v4vv",
    "oauth": {
        "domain": "auth01.bar-at.in",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://handyy.bar-at.in/",
        "redirectSignOut": "https://handyy.bar-at.in/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;